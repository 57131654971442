<template>
  <form-modal v-bind="bindChain.form" v-on="onChain.form">
    <v-form :ref="formRef">
      <v-row dense class="pt-3">
        <v-col cols="12">
          <v-text-field
            v-model="form.vigencia"
            name="tipo"
            label="Nombre de la vigencia"
            placeholder="Escriba el nombre de la vigencia..."
            :rules="[(v) => !!v || 'El campo nombre es requerido.']"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="form.meses"
            name="tipo"
            label="Tiempo vigencia"
            placeholder="Escriba el tiempo en meses de la vigencia..."
            type="number"
            :rules="[(v) => !!v || 'El campo meses es requerido.']"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="form.codigo"
            name="tipo"
            label="Código"
            placeholder="Escriba el código de la vigencia..."
            :rules="[(v) => !!v || 'El campo código es requerido.']"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="form.vigencia_descripcion"
            name="tipo"
            label="Descripción de la Vigencia"
            placeholder="Escriba descripción de la vigencia..."
            :rules="[(v) => !!v || 'El campo descripción es requerido.']"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </form-modal>
</template>

<script>
import { mapActions } from "vuex";
import FormMixin from "@/Mixins/SetFormMixin.js";

export default {
  name: "VigenciaFormModal",
  mixins: [FormMixin],
  data() {
    return {
      defaultTitle: "Formulario de vigencia",
      form: {
        vigencia: "",
        meses: "",
        vigencia_descripcion: "",
        codigo: "",
      },
      localUpdate: true,
    };
  },
  methods: {
    ...mapActions("NewProduct", ["CrearVigencia", "UpdateVigencia"]),

    async send() {
      this.loadingForm = true;
      let method = this.CrearVigencia;
      const formData = { ...this.form };
      if (this.formData) {
        method = this.UpdateVigencia;
        formData.id = this.formData._id;
      }
      method(formData)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.$emit("submit");
        })
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
  },
};
</script>
