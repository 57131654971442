<template>
  <v-container>
    <v-card>
      <v-data-table :search="search" v-bind="bind.table">
        <template #top>
          <table-top v-bind="bindChain.tableTop" v-on="onChain.tableTop" />
        </template>

        <template #item.actions="{ item }">
          <table-actions
            v-bind="bindChain.tableActions"
            v-on="onChain.tableActions(item)"
            :deleteDisabled="item[itemDisabled]"
          />
        </template>
      </v-data-table>
    </v-card>

    <Form v-model="dialog" v-bind="bind.form" v-on="on.form" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>
<script>
import PageMixin from "@/Mixins/SetPageMixin.js";
import Form from "@/components/Forms/cruds/vigencia.vue";
import { mapActions } from "vuex";

export default {
  name: "Vigencias",
  mixins: [PageMixin],
  components: {
    Form,
  },
  data: () => ({
    headers: [
      { text: "Vigencia", value: "vigencia", align: "left" },
      { text: "Código", value: "codigo", align: "left" },
      { text: "Descripción", value: "vigencia_descripcion", align: "left" },
      { text: "Meses", value: "meses", align: "center" },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
    vigencias: [],
  }),

  computed: {
    items() {
      return this.vigencias;
    },
  },

  methods: {
    ...mapActions("NewProduct", ["getVigencias", "deleteVigencia"]),

    async setup() {
      this.loading = true;
      try {
        const response = await this.getVigencias();
        this.vigencias = response;
      } catch (error) {
        this.snackbar(error.info);
      }
      this.loading = false;
    },

    saveItem() {
      this.closeForm();
      this.setup();
    },
    deleteMethod() {
      return this.deleteVigencia;
    },
  },
};
</script>
